.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  -webkit-transition: opacity 500ms ease-in;
  transition: opacity 500ms ease-in;
}

.switch-wrapper {
  height:   100%;
  position: relative;
  width:    100%;
}

.switch-wrapper > div,  .switch-wrapper > div > div{
  height:   100%;
  position: absolute;
  width:    100%;
}

