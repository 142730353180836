.switch-wrapper {
  height:   100%;
  position: relative;
  width:    100%;
}

.switch-wrapper > div,  .switch-wrapper > div > div{
  height:   100%;
  position: absolute;
  width:    100%;
}
